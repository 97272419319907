import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "create-sticer" }
const _hoisted_2 = { class: "create-sticer__title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "create-sticer__images-container" }
const _hoisted_6 = { class: "create-sticer__images-preview-wrap" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 5 }
const _hoisted_11 = { class: "create-sticer__photo-block" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = { class: "create-sticer__button" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StickerMock = _resolveComponent("StickerMock")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin/stickers",
      class: "create-sticer__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (!this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Add new "))
        : _createCommentVNode("", true),
      (this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Edit "))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Form, {
      onSubmit: _ctx.createSticker,
      class: "create-sticer__form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (!this.isPreview && this.stickerData?.image === undefined)
              ? (_openBlock(), _createBlock(_component_StickerMock, { key: 0 }))
              : _createCommentVNode("", true),
            (!this.isPreview && this.stickerData?.image === null)
              ? (_openBlock(), _createBlock(_component_StickerMock, { key: 1 }))
              : _createCommentVNode("", true),
            (this.isPreview && this.stickerData?.image === null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  src: _ctx.imagePreview,
                  class: "create-sticer__images-preview"
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            (this.isPreview && this.stickerData?.image !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: _ctx.imagePreview,
                  class: "create-sticer__images-preview"
                }, null, 8, _hoisted_8))
              : _createCommentVNode("", true),
            (!this.isPreview && this.stickerData?.image !== undefined)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 4,
                  src: this.stickerData?.image,
                  alt: "picture"
                }, null, 8, _hoisted_9))
              : _createCommentVNode("", true),
            (this.errorInstructor)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_AlertBlock, {
                    message: this.errorInstructor.image
                  }, null, 8, ["message"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("label", _hoisted_11, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "create-sticer__upload-photo first-button" }, "Upload", -1)),
              _createElementVNode("input", {
                type: "file",
                id: "file",
                ref: "file",
                accept: ".png, .jpg, .jpeg",
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload()))
              }, null, 544),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "create-sticer__upload-info" }, " Picture must be 720 px * 470 px and no more than 10 MB ", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[5] || (_cache[5] = _createElementVNode("label", {
            for: "title",
            class: "form-group-label"
          }, "Title ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "title",
            modelValue: this.title,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.title) = $event)),
            placeholder: "Example: Well-Behaved"
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.title
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "description",
            class: "form-group-label"
          }, "Description ", -1)),
          _createVNode(_component_Field, {
            as: "textarea",
            name: "description",
            placeholder: "",
            class: "form-control",
            modelValue: this.description,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.description) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.description
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", _hoisted_17, [
            (!this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Add"))
              : _createCommentVNode("", true),
            (this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Save"))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}