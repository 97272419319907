
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import { Form, Field, ErrorMessage } from "vee-validate";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import StickerMock from "@/assets/svg/sticker-mock.svg?inline";
import authHeader from "@/services/auth-header";

export default defineComponent({
  name: "CreateSticer",
  data() {
    return {
      imagePreview: null,
      isPreview: false,
      title: "",
      description: "",
      errorInstructor: null,
      file: null,
      stickerId: null,
      stickerData: {},
      editTeg: false,
    };
  },
  components: {
    ArrowBack,
    Form,
    Field,
    // ErrorMessage,
    AlertBlock,
    StickerMock,
  },
  mounted() {
    this.stickerId = this.$route.query.sticker;
    if (this.$route.query.edit !== undefined) {
      this.editTeg = this.$route.query.edit;
      this.getSticker(this.stickerId);
    }
  },
  methods: {
    getSticker(id) {
      this.$store.dispatch("stickers/getSticker", id).then(
        (res) => {
          this.stickerData = res.data;
          this.title = this.stickerData.title;
          this.description = this.stickerData.description;
        },
        (error) => {
          this.errorsticker = error.response.data.errors;
        }
      );
    },
    createSticker() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("description", this.description);
      if (this.file !== null) {
        formData.append("image", this.file);
      }
      if (this.editTeg === false) {
        this.axios
          .post(API_URL + "admin/encouragements", formData, {
            headers: authHeader(),
          })
          .then(
            () => {
              this.$router.push("/admin/stickers");
            },
            (error) => {
              this.errorInstructor = error.response.data.errors;
            }
          );
      } else {
        this.axios
          .post(
            API_URL + "admin/encouragements/" + this.stickerId + "/update",
            formData,
            { headers: authHeader() }
          )
          .then(
            () => {
              this.$router.push("/admin/stickers");
            },
            (error) => {
              this.errorInstructor = error.response.data.errors;
            }
          );
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
          this.stickerData.image = null;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
  },
});
